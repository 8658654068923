import gsap from 'gsap'
import { callAfterResize, sel, selAll } from './utils'

import 'vidstack/player/styles/default/theme.css'
import 'vidstack/player/styles/default/layouts/video.css'
import { VidstackPlayer, VidstackPlayerLayout } from 'vidstack/global/player'

export default function Event() {
  async function videoModal() {
    const videoBtn$ = sel('#customPlayerBtn')
    if (!videoBtn$) return

    const sec$ = sel('.even-intro-sec')
    const close$ = sec$.querySelector('.mod__x-w')
    const modW$ = sec$.querySelector('.mod-w')
    const mod$ = sec$.querySelector('.mod')
    const bg$ = sec$.querySelector('.mod-bg')
    modW$.classList.add('display-hide')

    const _player = sec$.querySelector('.custom_player')
    const player = await VidstackPlayer.create({
      target: _player,
      src: 'youtube/4UgnVc7kRxE',
      // src: 'vimeo/1061484821',
      poster: 'https://cdn.prod.website-files.com/655b9a852f3d2a5de3c90048/67ec2aaf454cc0ab8e781425_hero_q85.webp',
      crossOrigin: true,
      playsInline: true,
      viewType: 'video',
      load: 'idle',
      layout: new VidstackPlayerLayout(),
    })

    const tl = gsap
      .timeline({ defaults: { duration: 1, ease: 'power4.inOut' }, paused: true })
      .to(
        {},
        {
          onStart: () => {
            modW$.classList.remove('display-hide')
          },
          onReverseComplete: () => {
            modW$.classList.add('display-hide')
          },
          duration: 0.1,
        }
      )
      .to(modW$, { opacity: 1 })
      .from(mod$, { y: 50 }, 0)

    const closeModal = (e) => {
      e?.stopImmediatePropagation()
      tl.reverse()
      player.pause()
    }

    videoBtn$.onclick = (e) => {
      console.log('pl', player.state.canPlay)
      tl.play()

      if (player.state.canPlay) {
        player.play()
      } else {
        player.addEventListener(
          'can-play',
          (event) => {
            // console.log('canplay')
            player.play()
          },
          { once: true }
        )
      }
    }
    ;[close$, bg$].forEach((el) => {
      el.onclick = closeModal
    })

    player.addEventListener('ended', (event) => {
      closeModal()
    })

    callAfterResize(() => closeModal())
    // console.log('videoBtn$', videoBtn$)
  }
  videoModal()
}
