import gsap from 'gsap'
import { callAfterResize, pauseVideos, sel, selAll } from './utils'
import 'vidstack/player/styles/default/theme.css'
import 'vidstack/player/styles/default/layouts/video.css'
// import 'vidstack/player/styles/base.css'
// import 'vidstack/player/styles/plyr/theme.css'
import { VidstackPlayer, VidstackPlayerLayout, PlyrLayout } from 'vidstack/global/player'

pauseVideos()
export default async function EventPeter() {
  const sec$ = sel('.join-sec')
  const playerW$ = sec$.querySelector('.join__img__img-w')
  const playerBg$ = playerW$.querySelector('video')
  const close$ = sec$.querySelector('.mod__x-w')
  const modW$ = sec$.querySelector('.mod-w')
  const mod$ = sec$.querySelector('.mod')
  const bg$ = sec$.querySelector('.mod-bg')

  const _player = sel('#customVideo')
  const player = await VidstackPlayer.create({
    target: _player,
    src: 'youtube/4UgnVc7kRxE',
    // src: 'vimeo/1061484821',
    poster: 'https://cdn.prod.website-files.com/655b9a852f3d2a5de3c90048/67ec2aaf454cc0ab8e781425_hero_q85.webp',
    crossOrigin: true,
    playsInline: true,
    viewType: 'video',
    load: 'idle',
    layout: new VidstackPlayerLayout(),
  })

  modW$.classList.add('display-hide')

  const tl = gsap
    .timeline({ defaults: { duration: 1, ease: 'power4.inOut' }, paused: true })
    .to(
      {},
      {
        onStart: () => {
          modW$.classList.remove('display-hide')
        },
        onReverseComplete: () => {
          modW$.classList.add('display-hide')
        },
        duration: 0.1,
      }
    )
    .to(modW$, { opacity: 1 })
    .from(mod$, { y: 50 }, 0)

  const closeModal = (e) => {
    e?.stopImmediatePropagation()
    tl.reverse()
    playerBg$.play()
    player.pause()
  }

  playerW$.onclick = (e) => {
    // console.log('pl', player.state.canPlay)
    tl.play()
    playerBg$.pause()

    if (player.state.canPlay) {
      player.play()
    } else {
      player.addEventListener(
        'can-play',
        (event) => {
          // console.log('canplay')
          player.play()
        },
        { once: true }
      )
    }
  }
  ;[close$, bg$].forEach((el) => {
    el.onclick = closeModal
  })

  player.addEventListener('ended', (event) => {
    closeModal()
  })

  callAfterResize(() => closeModal())
}
